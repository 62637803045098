import React, { FC } from 'react';

import LogTimeseriesExplorer from '@/components/LogTimeseriesExplorer';

const TimeseriesTab: FC = () => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard (Timeseries)';
    }
  }, []);
  return <LogTimeseriesExplorer />;
};

export default TimeseriesTab;
