import React, { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import { HistogramPeriod } from '../../API';

interface LogTimeseriesGraphProps {
  series: { name: string; data: [DateTime, number][] }[];
  period: HistogramPeriod;
}

const LogTimeseriesGraph: FC<LogTimeseriesGraphProps> = ({
  series,
  period,
}) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        zoomType: 'x',
      },
      title: {
        text: `Usage by ${period.toLowerCase()}`,
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Usage',
        },
        min: 0,
      },
      legend: {
        enabled: series.length > 1 && series.length <= 20,
      },
      tooltip:
        period === 'MONTH'
          ? {
              xDateFormat: '%B %Y',
            }
          : {},
      credits: {
        enabled: false,
      },

      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get('rgba'),
              ],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },

      series: series.map(({ data, name }) => ({
        data: data.map((row) => [row[0].toMillis(), row[1]]),
        name,
        type: 'line',
      })),
    }}
  />
);

export default LogTimeseriesGraph;
