import React, { FC, useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Papa from 'papaparse';
import { LogTimeseriesTableRow } from './LogTimeseriesTable';

interface DownloadCsvButtonProps {
  data: LogTimeseriesTableRow[];
  groupName: string | null;
}

const createCsv = (
  data: LogTimeseriesTableRow[],
  groupName: string | null,
): string => {
  const fields = groupName ? ['Date', groupName, 'Count'] : ['Date', 'Count'];
  const csvRows: Array<string | number | Date>[] = [];
  for (const row of data) {
    const csvRow: Array<string | number | Date> = [row.date.toSQLDate()];
    if (groupName) csvRow.push(row.group);
    csvRow.push(row.count);
    csvRows.push(csvRow);
  }
  return Papa.unparse({
    fields,
    data: csvRows,
  });
};

const DownloadCsvButton: FC<DownloadCsvButtonProps> = ({ data, groupName }) => {
  const [csvUrl, setCsvUrl] = useState<string | undefined>();
  useEffect(() => {
    const csv = createCsv(data, groupName);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    setCsvUrl(window.URL.createObjectURL(csvData));
  }, [data, groupName]);
  return (
    <Button
      icon
      labelPosition="left"
      primary
      as="a"
      href={csvUrl}
      download="vfa-usage-data.csv"
    >
      <Icon name="download" />
      Download CSV
    </Button>
  );
};

export default DownloadCsvButton;
