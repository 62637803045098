import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';

export interface LogTimeseriesTableRow {
  date: DateTime;
  group?: string;
  count: number;
}

interface LogTimeseriesTableProps {
  data: LogTimeseriesTableRow[];
  groupName: string | null;
}

const LogTimeseriesTable: FC<LogTimeseriesTableProps> = ({
  data,
  groupName,
}) => (
  <Table compact striped>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Date</Table.HeaderCell>
        {groupName && <Table.HeaderCell>{groupName}</Table.HeaderCell>}
        <Table.HeaderCell>Count</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map((row) => (
        <Table.Row key={`${row.date.toMillis()}-${row.group}`}>
          <Table.Cell>{row.date.toISODate()}</Table.Cell>
          {groupName && <Table.Cell>{row.group}</Table.Cell>}
          <Table.Cell>{row.count}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default LogTimeseriesTable;
