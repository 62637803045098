import React, { FC, useContext, useState } from 'react';
import { Grid, Message, Segment } from 'semantic-ui-react';
import Papa from 'papaparse';
import { DateTime } from 'luxon';
import * as lodash from 'lodash';

import UserContext from '@/components/UserContext';
import DbContext from '@/components/DbContext';
import extractDateString from '@/lib/extractDateString';
import { getBackendConfig } from '@/lib/lambda';
import { callPaginatedPostApi } from '@/lib/paginated';

import LogTimeseriesControls, {
  LogTimeseriesControlQuery,
} from './LogTimeseriesControls';
import { HistogramPeriod, HistogramGroupBy } from '../../API';
import LogTimeseriesGraph from './LogTimeseriesGraph';
import LogTimeseriesTable, {
  LogTimeseriesTableRow,
} from './LogTimeseriesTable';
import DownloadCsvButton from './DownloadCsvButton';

type HistogramDataRow = [DateTime, number];
type HistogramData = { name: string; data: HistogramDataRow[] }[];

/**
 * Helper to fill in 0-valued data since AWS Athena and Postgres will only return values > 0 for timeseries data
 */
const fillInZeroPoints = (
  data: HistogramDataRow[],
  period: HistogramPeriod,
  startDate: DateTime,
  endDate: DateTime,
): HistogramDataRow[] => {
  if (data.length <= 1) return data;
  const nonZeroDates: { [dateStr: string]: HistogramDataRow } = {};
  data.forEach((row) => {
    nonZeroDates[row[0].toISODate()] = row;
  });
  const filledData: HistogramDataRow[] = [];
  let curDate = startDate;
  while (curDate <= endDate) {
    const curDateStr = curDate.toISODate();
    if (nonZeroDates[curDateStr]) {
      filledData.push(nonZeroDates[curDateStr]);
    } else {
      filledData.push([curDate, 0]);
    }
    // need to use luxon here to take into account daylight savings changes and other weirdness
    curDate = curDate.plus({ [period]: 1 });
  }
  return filledData;
};

const parseHistogramData = (
  histogramCsv: string,
  period: HistogramPeriod,
): HistogramData => {
  const csv = Papa.parse<{ date: string; groupVal?: string; total: string }>(
    histogramCsv,
    {
      header: true,
    },
  );
  const filteredRows = csv.data
    .filter((row) => row.date && row.total)
    .map((row) => ({
      ...row,
      date: DateTime.fromMillis(Date.parse(row.date.replace(/\s+.*/, ''))),
    }))
    .sort((a, b) => a.date.toMillis() - b.date.toMillis());
  const allGroups = lodash.groupBy(filteredRows, 'groupval');
  function getGroupName(groupVal) {
    let groupName;
    if (groupVal == null) {
      groupName = 'Results';
    } else if (groupVal === 'undefined' || groupVal === '') {
      groupName = 'none';
    } else {
      groupName = groupVal;
    }
    console.log(groupVal);
    return groupName;
  }
  const groupedSeries = Object.entries(allGroups).map(([groupVal, rows]) => ({
    name: getGroupName(groupVal),
    rows,
  }));
  const startDate = filteredRows[0].date;
  const endDate = filteredRows[filteredRows.length - 1].date;
  return groupedSeries.map(({ name, rows }) => {
    const rawData = rows
      .map(
        (row) =>
          [
            row.date.toUTC(0, { keepLocalTime: true }), // AWS returns a strange date format, make it a proper ISO date
            parseInt(row.total, 10),
          ] as HistogramDataRow,
      )
      .sort((a, b) => a[0].toMillis() - b[0].toMillis());
    return {
      name,
      data: fillInZeroPoints(
        rawData as HistogramDataRow[],
        period,
        startDate,
        endDate,
      ),
    };
  });
};

const parseTableData = (
  histogramData: HistogramData,
  groupData: boolean,
): LogTimeseriesTableRow[] => {
  const tableData: LogTimeseriesTableRow[] = [];
  for (const series of histogramData) {
    for (const row of series.data) {
      const tableRow = {
        date: row[0],
        count: row[1],
        group: groupData ? series.name : undefined,
      };

      tableData.push(tableRow);
    }
  }
  return tableData.sort((a, b) => a.date.toMillis() - b.date.toMillis());
};

const LogTimeseriesExplorer: FC = () => {
  const userContext = useContext(UserContext);
  const dbContext = useContext(DbContext);

  const [histogramPeriod, setHistogramPeriod] = useState<HistogramPeriod>(
    HistogramPeriod.DAY,
  );
  const [isSending, setIsSending] = useState(false);
  const [histogramData, setHistogramData] = useState<HistogramData | null>(
    null,
  );
  const [
    histogramGroupBy,
    setHistogramGroupBy,
  ] = useState<HistogramGroupBy | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const initStartQuery = async ({
    startDate,
    endDate,
    countries,
    votingStates,
    congressionalDistricts,
    joinDa,
    party,
    site,
    recordType,
    period,
    groupBy,
  }: LogTimeseriesControlQuery) => {
    if (isSending) return;
    setIsSending(true);
    setIsLoading(true);
    setHistogramData(null);
    setErrorMsg(null);
    setHistogramPeriod(period); // this is hacky, may want to fully control the form state from here in the future
    setHistogramGroupBy(groupBy); // this is hacky, may want to fully control the form state from here in the future
    try {
      const data = {
        startDate: extractDateString(startDate),
        endDate: extractDateString(endDate),
        votingStates: votingStates.length > 0 ? votingStates : undefined,
        congressionalDistricts:
          congressionalDistricts.length > 0
            ? congressionalDistricts
            : undefined,
        countries: countries.length > 0 ? countries : undefined,
        joinDa: joinDa || undefined,
        party: party || undefined,
        site: site || undefined,
        recordType,
        period,
        groupBy,
      };
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      config.headers.Accept = 'application/json';
      let result = null as any;
      try {
        result = (await callPaginatedPostApi(
          dbContext.db,
          `log-histogram`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }
      if (result.csvResults === undefined || result.count === undefined) {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        throw new Error('bad API response');
      }
      const histogramCsv = result.csvResults;
      setHistogramData(parseHistogramData(histogramCsv, period));
      setIsSending(false);
      setIsLoading(false);
    } catch (error) {
      setIsSending(false);
      setIsLoading(false);
      console.log('query failed ->', error);
      if (error.errors?.length > 0) {
        setErrorMsg(error.errors[0].message);
      }
    }
  };

  return (
    <Grid stackable>
      <Grid.Column width={4}>
        <Segment>
          <LogTimeseriesControls
            isLoading={isLoading}
            onQuery={initStartQuery}
          />
          {errorMsg && <Message color="red">{errorMsg}</Message>}
        </Segment>
      </Grid.Column>
      {histogramData && (
        <Grid.Column width={12}>
          <Segment>
            <LogTimeseriesGraph
              series={histogramData}
              period={histogramPeriod}
            />
          </Segment>
          <Segment style={{ maxHeight: '800px', overflowY: 'scroll' }}>
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <h3>Usage by {histogramPeriod.toLowerCase()}</h3>
                </Grid.Column>
                <Grid.Column floated="right" textAlign="right">
                  <DownloadCsvButton
                    data={parseTableData(histogramData, !!histogramGroupBy)}
                    groupName={histogramGroupBy}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <LogTimeseriesTable
              data={parseTableData(histogramData, !!histogramGroupBy)}
              groupName={histogramGroupBy}
            />
          </Segment>
        </Grid.Column>
      )}
    </Grid>
  );
};

export default LogTimeseriesExplorer;
